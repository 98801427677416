import React, { useState, useEffect } from "react";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import TextField from "@mui/material/TextField";
const TableFactura = ({ setFormData }) => {
  const generateRandomRow = (id) => ({
    id,
    cant: Math.floor(Math.random() * 100 + 1),
    u_medida: ["Kg", "m", "m2", "L"][Math.floor(Math.random() * 4)],
    description: `Producto ${id}`,
    price_unit: (Math.random() * 1000 + 1).toFixed(2),
    v_total: ((Math.random() * 1000 + 1) * 2).toFixed(2),
    disabled: true, // Estado de deshabilitado
  });

  const [tableData, setTableData] = useState([
    generateRandomRow(1),
    generateRandomRow(2),
    generateRandomRow(3),
    generateRandomRow(4),
  ]);

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, tableData }));
  }, [tableData]);

  const handleInputChange = (id, field, value) => {
    const updatedTable = tableData.map((row) =>
      row.id === id && !row.disabled
        ? {
            ...row,
            [field]: value,
            ...(field === "cant" || field === "price_unit"
              ? {
                  v_total:
                    (field === "cant" ? value : row.cant) *
                    (field === "price_unit" ? value : row.price_unit),
                }
              : {}),
          }
        : row,
    );

    setTableData(updatedTable);
    setFormData((prevData) => ({ ...prevData, tableData: updatedTable }));
  };

  const columns = [
    { dataKey: "cant", label: "CANTIDAD", width: "auto" },
    { dataKey: "u_medida", label: "UNIDAD DE MEDIDA", width: "auto" },
    { dataKey: "description", label: "DESCRIPCIÓN", width: "auto" },
    {
      dataKey: "price_unit",
      label: "VALOR UNITARIO",
      numeric: true,
      width: "auto",
    },
    { dataKey: "v_total", label: "VALOR TOTAL", numeric: true, width: "auto" },
  ];

  const customRenderers = {
    cant: (row) => (
      <TextField
        type="number"
        value={row.cant}
        label="Cantidad"
        variant="standard"
        size="small"
        disabled={row.disabled} // Deshabilitar si la fila está marcada como deshabilitada
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, "");
          const numericValue = Math.max(0, parseInt(value, 10) || 0);
          handleInputChange(row.id, "cant", numericValue);
        }}
        inputProps={{
          step: 1,
          min: 0,
        }}
      />
    ),
    u_medida: (row) => (
      <TextField
        type="text"
        value={row.u_medida}
        label="Unidad de Medida"
        variant="standard"
        size="small"
        disabled={row.disabled} // Deshabilitar si la fila está marcada como deshabilitada
        onChange={(e) => handleInputChange(row.id, "u_medida", e.target.value)}
      />
    ),
    description: (row) => (
      <TextField
        type="text"
        value={row.description}
        label="Descripción"
        variant="standard"
        size="small"
        disabled={row.disabled} // Deshabilitar si la fila está marcada como deshabilitada
        onChange={(e) =>
          handleInputChange(row.id, "description", e.target.value)
        }
      />
    ),
    price_unit: (row) => (
      <TextField
        type="text"
        value={row.price_unit}
        label="Valor Unitario"
        variant="standard"
        size="small"
        disabled={row.disabled} // Deshabilitar si la fila está marcada como deshabilitada
        onChange={(e) => {
          const value = e.target.value.trim();
          const numericValue = value.match(/^\d*\.?\d{0,9}$/)
            ? value
            : row.price_unit;
          handleInputChange(row.id, "price_unit", numericValue);
        }}
        inputProps={{
          step: "0.01",
          min: 0,
          inputMode: "decimal",
        }}
      />
    ),
    v_total: (row) => (
      <TextField
        type="number"
        value={row.v_total}
        label="Valor Total"
        variant="standard"
        size="small"
        disabled
      />
    ),
  };

  return (
    <div className="table-section">
      {tableData.length > 0 ? (
        <ReactVirtualizedTable
          columns={columns}
          data={tableData}
          customRenderers={customRenderers}
        />
      ) : (
        <p>No hay filas. Agrega una nueva fila para comenzar.</p>
      )}
    </div>
  );
};

export default TableFactura;
