import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Importar AuthProvider
import { GlobalProvider } from "./context/GlobalContext"; // Importar GlobalProvider
import { DataProvider } from "./context/DataContext"; // Contexto para datos globales
import MenuBar from "./components/Menu";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import FormCrearAsociados from "./pages/FormCrearAsociados";
import Login from "./pages/Login";
import FormOrdenCompra from "./pages/FormOrdenCompra";
import ConsultarOrdenCompra from "./pages/ConsultarOrdenCompra";
import PrivateRoute from "./components/PrivateRoute";
import HomeWelcomeBoard from "./pages/HomeWelcomeBoard"; // Importar HomeWelcomeBoard
import FormFacturasInterna from "./pages/FormFacturasInterna"; // Importar HomeWelcomeBoard
import DetalleOrden from "./components/OrdenCompraForms/DetalleOrden.js"; // Importa el componente para mostrar detalles

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <GlobalProvider>
          {/* Envolver toda la aplicación con AuthProvider y GlobalProvider */}
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <MenuBar />
                  </PrivateRoute>
                }
              >
                <Route index element={<Home />} />
                <Route
                  path="FormCrearAsociados"
                  element={<FormCrearAsociados />}
                />
                <Route path="Dashboard" element={<Dashboard />} />

                <Route path="HomeWelcomeBoard" element={<HomeWelcomeBoard />} />

                <Route path="FormOrdenCompra" element={<FormOrdenCompra />} />
                <Route
                  path="FormFacturasInterna"
                  element={<FormFacturasInterna />}
                />
                <Route
                  path="ConsultarOrdenCompra"
                  element={<ConsultarOrdenCompra />}
                />
                <Route path="/detalle-orden/:id" element={<DetalleOrden />} />
              </Route>
            </Routes>
          </Router>
        </GlobalProvider>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
