import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TableAdjuntos from "./TableAdjuntos.js";
import ArrowDownIcon from "../../../hooks/ArrowFlotant.js";
import ReactVirtualizedTable from "../../ReactVirtualizedTable.js";
import {
  fetchFormasPago,
  fetchClasesProveedor,
  fetchBancos,
  fetchTiposProveedor,
  fetchCentrosCostos,
} from "../../../services/apiService";

const Proveedor = ({ handleFormDataChange, parentRef }) => {
  const [isAutoretenedor, setIsAutoretenedor] = useState(false);
  const [isProveedorCertificado, setisProveedorCertificado] = useState(false);
  const [showArrowIcon, setShowArrowIcon] = useState(false);
  const { customRenderers, columns, data, uploadedFiles } = TableAdjuntos();
  const [metodoPago, setMetodoPago] = useState(""); // Valor inicial válido
  const [banco, setBanco] = useState("");
  const [tipoCuenta, setTipoCuenta] = useState("");
  const [claseProveedor, setClaseProveedor] = useState("");
  const [tipoProveedor, setTipoProveedor] = useState("");

  const [formasPagoOptions, setFormasPagoOptions] = useState([]);
  const [clasesProveedorOptions, setClasesProveedorOptions] = useState([]);
  const [bancosOptions, setBancosOptions] = useState([]);
  const [tiposProveedorOptions, setTiposProveedorOptions] = useState([]);
  const [centrosCostosOptions, setCentrosCostosOptions] = useState([]); // Lista de opciones
  const [selectedCentro, setSelectedCentro] = useState(null); // Centro seleccionad

  // Obtener los datos al cargar el componente
  useEffect(() => {
    const fetchData = async () => {
      const options = await fetchCentrosCostos();
      setCentrosCostosOptions(options);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setFormasPagoOptions(await fetchFormasPago());
      setClasesProveedorOptions(await fetchClasesProveedor());
      setBancosOptions(await fetchBancos());
      setTiposProveedorOptions(await fetchTiposProveedor());
    };
    fetchData();
  }, []);
  // Sincronizar archivos adjuntos con el estado general del formulario
  useEffect(() => {
    handleFormDataChange("adjuntos", uploadedFiles);
  }, [uploadedFiles, handleFormDataChange]);

  const scrollToTop = () => {
    if (parentRef && parentRef.current) {
      parentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef && parentRef.current) {
        const isAtBottom =
          parentRef.current.scrollHeight - parentRef.current.scrollTop ===
          parentRef.current.clientHeight;
        setShowArrowIcon(isAtBottom);
      }
    };

    if (parentRef && parentRef.current) {
      parentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (parentRef && parentRef.current) {
        parentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [parentRef]);

  return (
    <div className="proveedor-container">
      {showArrowIcon && (
        <div className="icon-arrowdown-aso" onClick={scrollToTop}>
          <ArrowDownIcon />
        </div>
      )}
      <div className="proveedor-section">
        <h1 className="sucursal-title">Proveedor</h1>
        <div className="person-type">
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isProveedorCertificado} // Vincula el estado al checkbox
              onChange={(e) => {
                setisProveedorCertificado(e.target.checked); // Actualiza el estado local
                handleFormDataChange(
                  "isProveedorCertificado",
                  e.target.checked,
                ); // Actualiza formData
              }}
            />
            <span className="checkbox-text">Proveedor Calificado</span>
          </label>

          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              checked={isAutoretenedor}
              onChange={(e) => {
                setIsAutoretenedor(e.target.checked);
                handleFormDataChange("isAutoretenedor", e.target.checked);
              }}
            />
            <span className="checkbox-text">Autoretenedor</span>
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                handleFormDataChange("isAutoretenedorICA", e.target.checked)
              }
            />
            <span className="checkbox-text">Autoretenedor ICA</span>
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                handleFormDataChange("isResponsableIVA", e.target.checked)
              }
            />
            <span className="checkbox-text">Responsable IVA</span>
          </label>
        </div>

        <div className="three-column-grid">
          {/* Método de Pago */}
          <FormControl className="input-form-control" required>
            <InputLabel id="metodo-pago-label" className="input-label">
              Método de Pago
            </InputLabel>
            <Select
              labelId="metodo-pago-label"
              id="metodo-pago-select"
              value={metodoPago} // Guarda el código (value) en el estado
              onChange={(e) => {
                setMetodoPago(e.target.value); // Guarda el código (value)
                handleFormDataChange("metodoPago", e.target.value); // Envía el código al formData
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {formasPagoOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label} {/* Muestra la descripción */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Clase de Proveedor */}
          <FormControl className="input-form-control" required>
            <InputLabel id="clase-proveedor-label" className="input-label">
              Clase de Proveedor
            </InputLabel>
            <Select
              labelId="clase-proveedor-label"
              id="clase-proveedor-select"
              value={claseProveedor}
              onChange={(e) => {
                setClaseProveedor(e.target.value); // Actualiza el estado local con el value
                handleFormDataChange("claseProveedor", e.target.value); // Envía el value al formData
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {clasesProveedorOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Campo para seleccionar el Centro de Costos */}
          <Autocomplete
            className="input-form-control"
            disablePortal
            options={centrosCostosOptions}
            getOptionLabel={(option) => option.label}
            value={selectedCentro}
            onChange={(event, value) => {
              setSelectedCentro(value || null); // Actualiza el estado
              handleFormDataChange("centroCostos", value?.value || null); // Propaga el cambio
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Centro de Costos"
                className="input-select"
                required
                InputLabelProps={{
                  className: "input-label",
                }}
                InputProps={{
                  ...params.InputProps,
                  className: "input-form-control",
                }}
              />
            )}
          />
        </div>

        <div className="three-column-grid">
          <FormControl className="input-form-control" required>
            <InputLabel id="banco-label" className="input-label">
              Banco
            </InputLabel>
            <Select
              labelId="banco-label"
              id="banco-select"
              value={banco} // Guarda el código (value) en el estado
              onChange={(e) => {
                setBanco(e.target.value); // Actualiza el estado local con el value
                handleFormDataChange("banco", e.target.value); // Envía el código al formData
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {bancosOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label} {/* Muestra el nombre del banco */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="input-form-control" required>
            <InputLabel id="tipo-cuenta-label" className="input-label">
              Tipo de Cuenta
            </InputLabel>
            <Select
              labelId="tipo-cuenta-label"
              id="tipo-cuenta-select"
              value={tipoCuenta}
              onChange={(e) => {
                setTipoCuenta(e.target.value); // Úsalo aquí
                handleFormDataChange("tipoCuenta", e.target.value); // Y aquí
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Corriente">Corriente</MenuItem>
              <MenuItem value="Ahorros">Ahorros</MenuItem>
              <MenuItem value="Exterior">Exterior</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="input-form-control" required>
            <InputLabel id="tipo-proveedor-label" className="input-label">
              Tipo de Proveedor
            </InputLabel>
            <Select
              labelId="tipo-proveedor-label"
              id="tipo-proveedor-select"
              value={tipoProveedor}
              onChange={(e) => {
                setTipoProveedor(e.target.value); // Actualiza el estado local con el value
                handleFormDataChange("tipoProveedor", e.target.value); // Envía el value al formData
              }}
              className="input-select"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {tiposProveedorOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="three-column-grid">
          <TextField
            id="numero-cuenta"
            label="Número de Cuenta"
            variant="outlined"
            className="input-textfield item-1"
            required
            onChange={(e) =>
              handleFormDataChange("numeroCuenta", e.target.value)
            }
          />

          <TextField
            id="titular-cuenta"
            label="Titular de la Cuenta"
            variant="outlined"
            className="input-textfield item-3"
            required
            onChange={(e) =>
              handleFormDataChange("titularCuenta", e.target.value)
            }
          />

          {isAutoretenedor && (
            <TextField
              id="numero-resolucion"
              label="Número de Resolución"
              variant="outlined"
              className="input-textfield item-2"
              required
              onChange={(e) =>
                handleFormDataChange("numeroResolucion", e.target.value)
              }
            />
          )}
        </div>
      </div>

      <ReactVirtualizedTable
        columns={columns}
        data={data}
        customRenderers={customRenderers}
      />
    </div>
  );
};

export default Proveedor;
