// src/components/DistribucionCostos.js
import React, { useState, useEffect } from "react";
import { X, Check } from "lucide-react";
import IconButton from "@mui/material/IconButton";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../../Forms/CustomAutocomplete";
import Loading from "../../../hooks/loading";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
import { useAlert } from "../../../hooks/useAlert";
const DistribucionCostos = ({
  className,
  text,
  formData,
  tipoDocumentoOptions,
  productOptions,
  groupedArray, // Recibir los grupos
  onClose,
  caller,
  emisor,
  onFieldChange,
  setRows, // Recibir la función para actualizar las filas
  orden, // Recibir la orden actual
  setAlertType,
}) => {
  useEffect(() => {
    if (caller === "noAgrp" && Array.isArray(productOptions)) {
      console.log(
        "Opciones de productos recibidas para No Agrupar:",
        productOptions,
      );

      if (productOptions.length > 0) {
        console.log("Opciones disponibles:", productOptions);
        console.log("Se recibió desde las opciones de No Agrupar Items.");
      } else {
        console.warn("No hay opciones de productos disponibles.");
      }
    } else if (caller === "distribucionCostos" && !productOptions.length) {
      console.warn("No hay productos válidos para distribucionCostos.");
    }
  }, [caller, productOptions]);

  const [totalPercentage, setTotalPercentage] = useState(null);
  const [sections, setSections] = useState(1); // Número de secciones visibles
  const { triggerAlert, Alert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const handlePercentageChange = (e, sectionIndex) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Permite solo números
    const currentInputValue = Number(
      formData[`distribucionPorcentaje${sectionIndex}`] || 0,
    );
    const numericValue = Math.min(
      Number(inputValue),
      100 - totalPercentage + currentInputValue,
    ); // Limita el valor máximo para no exceder 100%

    // Calcula el nuevo porcentaje total al incluir el valor del input
    const newTotalPercentage =
      totalPercentage - currentInputValue + numericValue;

    if (newTotalPercentage > 100) {
      return; // Evita actualizar si excede 100
    }

    setTotalPercentage(newTotalPercentage); // Actualiza el porcentaje total
    onFieldChange(
      `distribucionPorcentaje${sectionIndex}`,
      numericValue.toString(),
    ); // Actualiza el valor en el estado global

    // Si el porcentaje acumulado no alcanza 100, permite agregar nuevos inputs
    if (newTotalPercentage < 100 && sections === sectionIndex + 1) {
      setSections((prev) => prev + 1); // Agrega una nueva sección solo si no hemos alcanzado el 100%
    }

    // Si el porcentaje acumulado es exactamente 100, elimina inputs vacíos o con valores en 0
    if (newTotalPercentage === 100) {
      setSections((prevSections) => {
        // Genera un nuevo array de índices basado en las secciones actuales
        const validSections = Array.from({ length: prevSections }).filter(
          (_, index) => {
            const value = Number(
              formData[`distribucionPorcentaje${index}`] || 0,
            );
            return value > 0; // Solo conserva inputs con valores mayores a 0
          },
        );

        // Devuelve la nueva cantidad de secciones basadas en los inputs válidos
        return validSections.length;
      });
    }
  };

  // Renderización de secciones
  const sectionsToRender = Array.from({ length: sections }).filter(
    (_, index) => {
      // Mientras no se alcance el 100%, permite que los inputs vacíos aparezcan
      if (totalPercentage < 100) {
        return true; // Permite mostrar todos los inputs
      }
      // Una vez que se alcance el 100%, elimina los inputs vacíos o con valor 0
      const value = formData[`distribucionPorcentaje${index}`];
      return value !== "" && Number(value) > 0;
    },
  );

  const groupOptions = Object.keys(groupedArray).map((group) => ({
    value: group, // Nombre del grupo como valor
    label: group, // Nombre del grupo para mostrar
    content: groupedArray[group], // Contenido del grupo
  }));

  const handleFinalAprobar = () => {
    // Validar que todos los inputs tengan valores seleccionados
    // Validar que todos los campos generados tengan valores seleccionados
    const allFieldsFilled = sectionsToRender.every((_, index) => {
      return (
        formData[`centroCostos${index}`]?.trim() &&
        formData[`distribucionPorcentaje${index}`]?.trim()
      );
    });

    if (!allFieldsFilled) {
      alert(
        "Por favor, asegúrese de que todos los campos generados tengan valores seleccionados.",
      );
      return;
    }
    const allSelected =
      caller === "costos"
        ? groupOptions.every((option) =>
            sectionsToRender.some(
              (_, index) =>
                formData[`distribucionxGrupos${index}`] === option.value,
            ),
          )
        : caller === "noAgrp"
          ? productOptions.every((option) =>
              sectionsToRender.some(
                (_, index) =>
                  formData[`selectedProduct${index}`] === option.value,
              ),
            )
          : true;

    if (!allSelected) {
      alert(
        "Tiene Items/Grupos de la factura sin Seleccionar, Por favor seleccione la totalidad de los Items a distribuir por Porcentaje",
      );
      return;
    }

    setIsLoading(true); // Activa el loading

    // Introduce un pequeño retraso para asegurar que el estado de loading se refleja
    setTimeout(() => {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.orden === orden ? { ...row, estadoFactura: "Aprobado" } : row,
        ),
      );
      console.log(`Orden ${orden} actualizada a estado: Aprobado`);
      setIsLoading(false); // Desactiva el loading
      onClose(); // Opcional: cerrar el modal o alerta tras aprobar
    }, 500); // Retraso de 500ms
    triggerAlert("invoiceApproved", {});
  };

  return (
    <div className="tramite-factura-container">
      {isLoading && <Loading />}
      <div className="form-header-alerts">
        <p className={className}>{text}</p>
        <Link
          to="#"
          onClick={(event) => {
            event.preventDefault(); // Evita la redirección
            setAlertType("tramiteFactura"); // Cambia la alerta activa a "tramiteFactura"
          }}
        >
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <div className="cont-factura">
        <div className="cont-factura-2">
          <p className={className}>EMISOR: {emisor}</p>
          <div className="three-column-grid">
            <p className={className}>
              {caller === "costos"
                ? "Grupos"
                : caller === "noAgrp"
                  ? "Items"
                  : ""}
            </p>

            <p className={className}>Centro de Costos</p>
            <p className={className}>{totalPercentage}% = 100%</p>
          </div>
          {sectionsToRender.map((_, index) => (
            <div className="three-column-grid" key={index}>
              {caller === "costos" && (
                <CustomAutocomplete
                  className="item-3"
                  options={groupOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                  }))}
                  value={
                    groupOptions.find(
                      (option) =>
                        option.value ===
                        formData[`distribucionxGrupos${index}`],
                    ) || null
                  }
                  onChange={(event, value) => {
                    if (value) {
                      onFieldChange(`distribucionxGrupos${index}`, value.value);
                    }
                  }}
                  label={`Seleccione Grupo ${index + 1}`}
                  required={true}
                />
              )}
              {caller === "noAgrp" && (
                <CustomAutocomplete
                  className="item-3"
                  options={productOptions || []} // Usa las opciones recibidas o un array vacío
                  value={
                    productOptions.find(
                      (option) =>
                        option.value === formData[`selectedProduct${index}`],
                    ) || null
                  } // Valor único por cada índice
                  onChange={(event, value) => {
                    if (value) {
                      console.log(
                        `Item seleccionado en índice ${index}:`,
                        value,
                      );
                      onFieldChange(`selectedProduct${index}`, value.value); // Actualiza el estado con clave única por índice
                    } else {
                      onFieldChange(`selectedProduct${index}`, null); // Limpia el valor si no hay selección
                    }
                  }}
                  label={`Seleccione Item ${index + 1}`}
                  required={true}
                />
              )}

              <CustomAutocomplete
                className="item-3"
                options={tipoDocumentoOptions.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                value={
                  tipoDocumentoOptions.find(
                    (option) =>
                      option.label === formData[`centroCostos${index}`],
                  ) || null
                }
                onChange={(event, value) => {
                  onFieldChange(`centroCostos${index}`, value?.label || "");
                }}
                label={`Seleccione Centro de Costos ${index + 1}`}
                required={true}
              />

              <CustomTextField
                id={`distribucionPorcentaje${index}`}
                label="Porcentaje"
                className="item-2"
                value={formData[`distribucionPorcentaje${index}`] || ""}
                onChange={(e) => handlePercentageChange(e, index)}
                required={true}
              />
            </div>
          ))}
        </div>

        <Alert />
      </div>
      <div className="button-container-tramite">
        <button onClick={onClose} className="alert-button red">
          <X className="icon" />
          Cerrar
        </button>
        <button className="alert-button green" onClick={handleFinalAprobar}>
          <Check className="icon" />
          Aprobar
        </button>
      </div>
    </div>
  );
};

export default DistribucionCostos;
