import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FileText } from "lucide-react"; // Ícono de PDF
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import PaginationComponent from "../PaginationComponent";
import "../../styles/components/tableFacturaInt.css";
import Loading from "../../hooks/loading"; // Componente de Loading
import { useAlert } from "../../hooks/useAlert"; // Hook personalizado para alertas

const TableFactura = ({ filters }) => {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [timeLeft, setTimeLeft] = useState(30); // Temporizador inicial
  const [isLoading, setIsLoading] = useState(false); // Estado de loading
  const [selectedProducts, setSelectedProducts] = useState([]); // Productos seleccionados
  const { triggerAlert, Alert } = useAlert(); // Utilizamos el hook de alertas
  const rowsPerPage = 10;

  // Temporizador en tiempo real
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 30)); // Restablecer a 30 cuando llegue a 0
    }, 1000); // Intervalo de 1 segundo

    return () => clearInterval(timer); // Limpieza del intervalo
  }, []);

  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const getRandomDate = () => {
    const today = new Date();
    const randomDays = getRandomNumber(0, 30);
    const randomDate = new Date(today);
    randomDate.setDate(today.getDate() + randomDays);
    return randomDate;
  };

  const generateProducts = (category) => {
    const techProducts = [
      "Laptop",
      "Smartphone",
      "Tablet",
      "Monitor",
      "Mouse",
      "Keyboard",
      "Headphones",
      "Webcam",
      "Smartwatch",
      "External Hard Drive",
      "Gaming Console",
      "Drone",
      "Smart Speaker",
      "VR Headset",
      "Graphics Card",
      "Power Bank",
      "Portable Projector",
      "Bluetooth Tracker",
      "Wireless Charger",
      "3D Printer",
      "Streaming Device",
      "Noise-Canceling Headphones",
      "Gaming Mouse",
      "E-Reader",
      "Portable SSD",
      "Network Router",
      "Action Camera",
      "Gaming Chair",
      "Surround Sound System",
      "Smart Doorbell",
      "Fitness Tracker",
      "Smart Thermostat",
      "Smart Lock",
      "Robot Vacuum",
      "Home Security Camera",
      "Desktop Computer",
      "NAS Storage",
      "Electric Scooter",
      "Video Doorbell",
      "Bluetooth Speaker",
      "LED Desk Lamp",
      "Foldable Smartphone",
      "AI Assistant Device",
      "Streaming Microphone",
      "Dual Monitor Stand",
      "Wireless Keyboard and Mouse",
      "Docking Station",
      "Gaming Keyboard",
      "Thermal Printer",
      "Wireless Earbuds",
      "Portable Charger",
      "Mechanical Keyboard",
      "Gaming Monitor",
      "HD Webcam",
      "4K Projector",
      "Noise-Isolating Earbuds",
      "Wireless Charging Pad",
      "Solar Power Bank",
      "Action Drone",
      "Gaming Headset",
      "Laptop Stand",
      "USB-C Hub",
      "Smart Glasses",
      "Portable Battery",
      "Photo Printer",
      "Graphics Tablet",
      "Drawing Tablet",
      "Ergonomic Mouse",
      "VR Controllers",
      "Video Editing Monitor",
      "Streaming Headset",
      "Mini Projector",
      "Studio Lights",
      "4K Smart TV",
      "Home Assistant",
      "Network Switch",
      "Smart LED Strip",
      "Smart Plug",
      "Smart Scale",
      "Gaming Desk",
      "USB Flash Drive",
      "Wireless HDMI Transmitter",
      "Capture Card",
      "Gaming Mouse Pad",
      "Stylus Pen",
      "Portable Hotspot",
      "Wireless Gaming Controller",
      "Gaming Laptop",
      "Computer Speaker",
      "Docking Station Hub",
      "Digital Photo Frame",
      "Webcam Cover",
      "External Graphics Card",
      "8K Monitor",
      "Dual Band Router",
      "Screen Protector",
      "Laptop Cooling Pad",
      "Smart UV Sanitizer",
      "Noise-Isolation Booth",
    ];

    const officeProducts = [
      "Printer",
      "Stapler",
      "Desk Chair",
      "Notebook",
      "Pen",
      "Paper",
      "Desk Lamp",
      "Calendar",
      "File Cabinet",
      "Clipboard",
      "Paper Shredder",
      "Desk Organizer",
      "Sticky Notes",
      "Whiteboard",
      "Marker",
      "Hole Puncher",
      "Corkboard",
      "Ruler",
      "Glue Stick",
      "Office Phone",
      "Eraser",
      "Bulletin Board",
      "Conference Speakerphone",
      "Standing Desk",
      "Filing Box",
      "Office Chair Mat",
      "Monitor Stand",
      "Task Lamp",
      "Pen Holder",
      "Paper Clips",
      "Binder Clips",
      "Document Tray",
      "Index Cards",
      "Document Scanner",
      "Meeting Timer",
      "Conference Phone",
      "Adjustable Desk",
      "Laser Pointer",
      "Highlighter",
      "Dry Erase Board",
      "Magnetic Board",
      "Dry Erase Markers",
      "Push Pins",
      "Post-it Flags",
      "Clipboard Folder",
      "Laminator",
      "Desk Divider",
      "Cable Management Box",
      "Computer Desk",
      "Desk Mat",
      "Mouse Pad",
      "Cable Clips",
      "Desk Drawer Organizer",
      "Floor Lamp",
      "Foot Rest",
      "Standing Desk Converter",
      "Monitor Riser",
      "Printer Stand",
      "Label Maker",
      "Label Tape",
      "Paper Guillotine",
      "Desk Clock",
      "Office Cart",
      "Filing Cabinet",
      "Document Holder",
      "Magnifying Glass",
      "Stamp Pad",
      "Ink Refill",
      "Paper Rolls",
      "Mail Organizer",
      "Trash Bin",
      "Recycling Bin",
      "Adjustable Stool",
      "Anti-Fatigue Mat",
      "Pencil Sharpener",
      "Pocket Calculator",
      "Desktop Calendar",
      "Task Management Board",
      "Pen Refill",
      "Correction Tape",
      "Glue Gun",
      "Packing Tape Dispenser",
      "Bookends",
      "Office Backpack",
      "Pencil Case",
      "Portable Desk",
      "Laptop Desk",
      "Magazine Holder",
      "Shelving Unit",
      "Drawer Unit",
      "Conference Table",
      "Office Plant",
      "Air Purifier",
      "File Organizer",
      "Desk Calendar Stand",
      "Sticky Tape Dispenser",
      "Name Plate",
      "Desk Cubicle Organizer",
      "Business Card Holder",
    ];

    const sourceArray =
      category === "tecnología" ? [...techProducts] : [...officeProducts];

    if (sourceArray.length < 10) {
      throw new Error(
        "Not enough unique products to generate 10 distinct items.",
      );
    }

    const selectedProducts = [];

    while (selectedProducts.length < 5) {
      const randomIndex = getRandomNumber(0, sourceArray.length - 1);
      selectedProducts.push(sourceArray[randomIndex]);
      sourceArray.splice(randomIndex, 1); // Remove the selected item to prevent repetition
    }

    return selectedProducts;
  };

  const generateRows = () => {
    const estadoFacturas = ["Pendiente", "Aprobado", "Rechazado"];
    const emisores = [
      "Whirpool",
      "Temuu",
      "BancoBogota",
      "Bancolombia",
      "Netflix",
      "Agua",
      "Luz",
    ];

    return Array.from({ length: 50 }, (_, index) => {
      const isTechCategory = index % 2 === 0; // Alternar entre categorías
      return {
        orden: index + 1,
        emisor: emisores[getRandomNumber(0, emisores.length - 1)],
        fecha: getRandomDate().toLocaleDateString("es-CO"),
        valor: `$${(Math.random() * 1000).toFixed(2)}`,
        estadoFactura:
          estadoFacturas[getRandomNumber(0, estadoFacturas.length - 1)],
        documento: `/detalle-orden/${index + 1}`,
        productos: generateProducts(isTechCategory ? "tecnología" : "oficina"), // Nuevo campo productos
      };
    });
  };

  useEffect(() => {
    const generatedRows = generateRows();
    setRows(generatedRows);
  }, []);

  const filteredRows = rows.filter((row) => {
    const from = filters.fechaordenDesde
      ? new Date(filters.fechaordenDesde)
      : null;
    const to = filters.fechaordenHasta
      ? new Date(filters.fechaordenHasta)
      : null;
    const fecha = new Date(row.fecha);

    const isWithinDateRange = (!from || fecha >= from) && (!to || fecha <= to);
    const matchesEstado =
      !filters.estadOrden || row.estadoFactura === filters.estadOrden;

    return isWithinDateRange && matchesEstado;
  });

  const sortedRows = filteredRows.sort((a, b) => {
    if (a.estadoFactura === "Pendiente" && b.estadoFactura !== "Pendiente")
      return -1;
    if (a.estadoFactura !== "Pendiente" && b.estadoFactura === "Pendiente")
      return 1;
    return 0;
  });

  const getPaginatedRows = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedRows.slice(startIndex, endIndex);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleAprobar = (orden) => {
    setIsLoading(true);

    setTimeout(() => {
      const row = rows.find((row) => row.orden === orden);

      if (row) {
        console.log("Productos de la fila seleccionada:", row.productos);
        console.log("Emisor seleccionado:", row.emisor);

        setSelectedProducts([...row.productos]); // Guarda los productos en el estado

        // Llama a triggerAlert con la estructura esperada, pasando la orden y el estado
        triggerAlert("groupItems", {
          products: [...row.productos],
          emisor: row.emisor,
          orden: row.orden, // Agrega la orden para identificarla
          setRows,
          estadoFactura: row.estadoFactura, // Agrega el estado actual de la factura
        });
      }

      setIsLoading(false); // Finaliza la carga
    }, 1000);
  };

  const handleRechazar = (orden) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.orden === orden ? { ...row, estadoFactura: "Rechazado" } : row,
      ),
    );
    triggerAlert("invoiceRejected", {});
  };

  const getSemaforoColor = () => {
    if (timeLeft > 20) return "verde";
    if (timeLeft > 10) return "amarillo";
    return "rojo";
  };

  const columns = [
    { dataKey: "emisor", label: "EMISOR", width: "auto" },
    { dataKey: "fecha", label: "FECHA", width: "auto" },
    { dataKey: "valor", label: "VALOR", width: "auto" },
    { dataKey: "estadoFactura", label: "ESTADO", width: "auto" },
    { dataKey: "documento", label: "PDF", width: "auto" },
    { dataKey: "tramiteFactura", label: "TRAMITAR", width: "auto" },
    { dataKey: "alerta", label: "ALERTA", width: "auto" },
  ];

  const customRenderers = {
    documento: (rowData) => (
      <Link to={rowData.documento} className="link" title="Ver PDF">
        <FileText size={20} />
      </Link>
    ),
    tramiteFactura: (rowData) => {
      if (
        rowData.estadoFactura === "Aprobado" ||
        rowData.estadoFactura === "Rechazado"
      ) {
        return null;
      }
      return (
        <div className="button-container">
          <button
            type="button"
            className="tramitar-button green"
            onClick={() => handleAprobar(rowData.orden)}
          >
            Aprobar
          </button>
          <button
            type="button"
            className="tramitar-button red"
            onClick={() => handleRechazar(rowData.orden)}
          >
            Rechazar
          </button>
        </div>
      );
    },
    alerta: () => (
      <div className={`semaforo ${getSemaforoColor()}`}>
        <span
          className={`semaforo-circulo ${
            timeLeft > 20 ? "verde" : timeLeft > 10 ? "amarillo" : "rojo"
          }`}
          title="Semáforo"
        ></span>
      </div>
    ),
  };

  return (
    <div className="table-section up">
      {isLoading && <Loading />}
      <ReactVirtualizedTable
        columns={columns}
        data={getPaginatedRows()}
        customRenderers={customRenderers}
      />
      <PaginationComponent
        totalItems={sortedRows.length}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handleChangePage}
      />
      <Alert selectedProducts={selectedProducts} />{" "}
      {/* Pasar productos seleccionados */}
    </div>
  );
};

export default TableFactura;
