import React from "react";
import "../styles/components/alertStyle.css";
import { useGlobalContext } from "../context/GlobalContext";

const HomeWelcomeBoard = () => {
  const { user, company, area, centro_costos } = useGlobalContext();

  return (
    <div className="container-welcome">
      <h2 className="approval-text-ver">Módulo {centro_costos}</h2>
      <h2 className="approval-text-ver">
        ¡Bienvenido {user}! <br />
        {area} <br />
        {company}
      </h2>
    </div>
  );
};

export default HomeWelcomeBoard;
