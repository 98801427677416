import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Importar dayjs

export const CustomTextField = ({
  id,
  label,
  value,
  onChange,
  required = false,
  disabled = false,
  error = false,
  helperText = "",
}) => (
  <TextField
    id={id}
    label={label}
    variant="outlined"
    className="input-textfield"
    value={value}
    onChange={onChange}
    required={required}
    disabled={disabled} // Respetar la propiedad disabled
    error={error}
    helperText={helperText}
  />
);

export const CustomAutocomplete = ({
  options,
  value,
  onChange,
  label,
  required = false,
  disabled = false,
}) => (
  <Autocomplete
    className="input-form-control"
    disablePortal
    options={disabled ? [] : options} // Si está deshabilitado, no muestra opciones
    value={disabled ? null : value} // Si está deshabilitado, limpia el valor
    onChange={disabled ? null : onChange} // Evita cualquier cambio si está deshabilitado
    isOptionEqualToValue={(option, value) => option.label === value?.label}
    open={disabled ? false : undefined} // Si está deshabilitado, no permite abrir. Comportamiento normal si no.
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        className="input-select"
        required={required}
        disabled={disabled} // Deshabilita el campo de texto si corresponde
        InputLabelProps={{
          className: "input-label",
        }}
        InputProps={{
          ...params.InputProps,
          className: "input-form-control",
          readOnly: disabled, // Evita que el usuario escriba en el campo si está deshabilitado
        }}
      />
    )}
  />
);

export const CustomSelect = ({
  id,
  label,
  value,
  options = [],
  onChange,
  required = false,
  disabled = false,
}) => {
  const defaultOptions = [
    { value: "option1", label: "Opción 1" },
    { value: "option2", label: "Opción 2" },
    { value: "option3", label: "Opción 3" },
  ];

  const finalOptions = options.length > 0 ? options : defaultOptions;

  return (
    <FormControl
      className="input-form-control"
      required={required}
      disabled={disabled} // Respetar la propiedad disabled
    >
      <InputLabel id={`${id}-label`} className="input-label">
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={label}
        onChange={disabled ? () => {} : onChange} // Deshabilitar cambios si está deshabilitado
        className="input-select"
        disabled={disabled} // Respetar la propiedad disabled
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {finalOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const CustomTextarea = ({
  id,
  label,
  value,
  onChange,
  required = false,
  placeholder = "",
  minRows = 3,
  disabled = false,
}) => (
  <FormControl
    className="input-form-control observation"
    required={required}
    disabled={disabled} // Respetar la propiedad disabled
  >
    <InputLabel htmlFor={id} className="input-label">
      {label}
    </InputLabel>
    <TextareaAutosize
      id={id}
      className="custom-textarea"
      value={value}
      onChange={(e) => (disabled ? null : onChange(e.target.value))} // Deshabilitar cambios si está deshabilitado
      placeholder={placeholder}
      minRows={minRows}
      disabled={disabled} // Respetar la propiedad disabled
    />
  </FormControl>
);

export const CustomDateField = ({
  id,
  label,
  value,
  onChange,
  required = false,
  minDate = null,
  disabled = false,
}) => {
  const processedValue = value && value !== "" ? dayjs(value) : null;
  const processedMinDate = minDate && minDate !== "" ? dayjs(minDate) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={processedValue}
        onChange={disabled ? () => {} : onChange} // Deshabilitar cambios si está deshabilitado
        minDate={processedMinDate}
        disabled={disabled} // Respetar la propiedad disabled
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            required={required}
            className="input-textfield"
            disabled={disabled} // Respetar la propiedad disabled
          />
        )}
      />
    </LocalizationProvider>
  );
};
