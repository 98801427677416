import React, { useState, useRef, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import Dates3 from "../OrdenCompraForms/Dates1DetailsOrden.js";
import "../../styles/pages/OrdenCompra.pages.css";
import { Check, X } from "lucide-react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../../context/GlobalContext.js";
import {
  CustomTextField,
  CustomSelect,
  CustomTextarea,
  CustomDateField,
} from "../Forms/CustomAutocomplete.js";
import TableFactura from "../OrdenCompraForms/TableFacturaAprvRechz.js";
import { Link } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert"; // Hook personalizado
import Loading from "../../hooks/loading.js"; // Componente de Loading

const DetalleOrden = () => {
  const { user, area, company } = useGlobalContext();
  const { triggerAlert, Alert } = useAlert();
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el loading
  const [isActionCompleted, setIsActionCompleted] = useState(false); // Estado para deshabilitar botones
  const [formData, setFormData] = useState({
    numeroOrden: "",
    centroCostos: "",
    nit: "",
    senores: "",
    sucursales: "",
    telefono: "",
    metodoPago: "",
    subtotal: 0,
    iva: 19,
    total: "",
    fechaAnteriorCompra: null,
    fechaEntrega: null,
    justificacion: "",
    observaciones: "",
    fechaAnteriordeCompra: "",
    estadoOrden: "", // Estado de la orden: "Aprobado" o "Rechazado"
    aprobadoRechazadoPor: "", // Usuario que aprueba o rechaza
    tableData: [
      {
        id: 1,
        cant: "",
        u_medida: "",
        description: "",
        price_unit: "",
        v_total: "",
      },
    ],
    usuario: user,
    cargo: area,
  });

  // Función para mostrar el loading y luego realizar la acción
  const executeWithLoading = async (action) => {
    setIsLoading(true); // Activa el loading
    setIsActionCompleted(false); // Asegura que los botones estén visibles antes de ejecutar
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simula un tiempo de carga
    action(); // Ejecuta la acción pasada como argumento
    setIsLoading(false); // Desactiva el loading
    setIsActionCompleted(true); // Marca que la acción está completada
  };

  const handleApprove = () => {
    setFormData((prevData) => ({
      ...prevData,
      estadoOrden: "Aprobado",
      aprobadoRechazadoPor: user,
    }));
    triggerAlert("success", {});
  };

  const handleReject = () => {
    setFormData((prevData) => ({
      ...prevData,
      estadoOrden: "Rechazado",
      aprobadoRechazadoPor: user,
    }));

    // Llama a triggerAlert sin pasar parámetros adicionales
    triggerAlert("error", {});
  };

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const Dates3Component = useMemo(
    () => <Dates3 handleFormDataChange={handleFormDataChange} />,
    [],
  );

  return (
    <div className="creation-form-container">
      {isLoading && <Loading />} {/* Muestra el componente Loading */}
      <div className="form-header-ordencompra">
        <p>
          <strong>{company}</strong>
        </p>
        <h1 className="form-title-ordencompra">
          Orden de Compra y/o Servicios
        </h1>
        <Link to="/ConsultarOrdenCompra">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content">
        <div ref={useRef(null)} className="form-date-basic---orden">
          {Dates3Component}
          <TableFactura formData={formData} setFormData={setFormData} />
          <div className="factura-section">
            <div className="two-column-grid">
              <CustomSelect
                id="metodo-pago"
                label="Método de Pago"
                value={formData.metodoPago}
                options={[
                  { value: "Efectivo", label: "Efectivo" },
                  { value: "Transferencia", label: "Transferencia" },
                ]}
                disabled
              />
              <CustomTextField
                id="subtotal"
                label="SUBTOTAL"
                value={formData.subtotal}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-sin-limite"
                label="Fecha Anterior de Compra"
                value={formData.fechaAnteriordeCompra}
                disabled
              />
              <CustomTextField
                id="iva"
                label="IVA (%)"
                value={formData.iva}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-entrega"
                label="Fecha de Entrega"
                value={formData.fechaEntrega}
                disabled
              />
              <CustomTextField
                id="total"
                label="TOTAL"
                value={formData.total}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomTextarea
                id="justificacion"
                value={formData.justificacion}
                placeholder="Escribe aquí la justificación..."
                minRows={5}
                disabled
              />
              <CustomTextarea
                id="observaciones"
                value={formData.observaciones}
                placeholder="Escribe aquí las observaciones..."
                minRows={5}
                disabled
              />
            </div>
            <div className="two-column-grid sp">
              <p>
                <strong>Solicitado por:</strong> {user} <br />
                <strong>Cargo:</strong> {area}
              </p>
              <p>
                <strong>
                  {formData.estadoOrden
                    ? `${formData.estadoOrden} por:`
                    : "Aprobado o rechazado por:"}
                </strong>{" "}
                {formData.aprobadoRechazadoPor || ""} <br />
                <strong>Cargo:</strong> {area}
              </p>
            </div>
          </div>
        </div>
        <div className="verorden-button-container-orden">
          {!isActionCompleted && (
            <>
              <button
                type="button"
                className="alert-button red"
                onClick={() => executeWithLoading(handleReject)}
                disabled={isLoading} // Deshabilita si el loading está activo
              >
                <X className="icon" />
                Rechazar
              </button>
              <button
                type="button"
                className="alert-button green"
                onClick={() => executeWithLoading(handleApprove)}
                disabled={isLoading} // Deshabilita si el loading está activo
              >
                <Check className="icon" />
                Aprobar
              </button>
            </>
          )}
        </div>

        <Alert />
      </form>
    </div>
  );
};

export default DetalleOrden;
