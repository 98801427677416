// OrdenVerify.js
import React from "react";

const OrdenVerify = ({ formData }) => {
  return (
    <div className="form-data">
      <h3 className="section-title-ver">Información del Solicitante</h3>
      {formData.fechaHoraActual && (
        <p>
          <strong>Fecha:</strong>{" "}
          {new Date(formData.fechaHoraActual).toLocaleString("es-CO", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </p>
      )}

      {formData.usuario && (
        <p>
          <strong>Usuario:</strong> {formData.usuario}
        </p>
      )}
      {formData.cargo && (
        <p>
          <strong>Cargo:</strong> {formData.cargo}
        </p>
      )}

      <h3 className="section-title-ver">Detalles de la Orden</h3>

      {formData.centroCostos && (
        <p>
          <strong>Centro de Costos:</strong> {formData.centroCostos}
        </p>
      )}
      {formData.nit && (
        <p>
          <strong>NIT:</strong> {formData.nit}
        </p>
      )}
      {formData.senores && (
        <p>
          <strong>Señor(es):</strong> {formData.senores}
        </p>
      )}
      {formData.sucursales && (
        <p>
          <strong>Sucursales:</strong> {formData.sucursales}
        </p>
      )}
      {formData.telefono && (
        <p>
          <strong>Teléfono:</strong> {formData.telefono}
        </p>
      )}

      <h3 className="section-title-ver">Información Financiera</h3>
      {formData.metodoPago && (
        <p>
          <strong>Método de Pago:</strong> {formData.metodoPago}
        </p>
      )}
      {formData.subtotal !== null && (
        <p>
          <strong>Subtotal:</strong> {formData.subtotal}
        </p>
      )}
      {formData.iva !== null && (
        <p>
          <strong>IVA:</strong> {formData.iva}%
        </p>
      )}
      {formData.total !== null && (
        <p className="red-text">
          <strong>Total:</strong> {formData.total}
        </p>
      )}

      <h3 className="section-title-ver">Fechas</h3>
      {formData.fechaAnteriordeCompra && (
        <p>
          <strong>Fecha Anterior de Compra:</strong>{" "}
          {new Date(formData.fechaAnteriordeCompra).toLocaleDateString("es-CO")}
        </p>
      )}
      {formData.fechaEntrega && (
        <p>
          <strong>Fecha de Entrega:</strong>{" "}
          {new Date(formData.fechaEntrega).toLocaleDateString("es-CO")}
        </p>
      )}

      <h3 className="section-title-ver">Comentarios</h3>
      {formData.justificacion && (
        <p>
          <strong>Justificación:</strong> {formData.justificacion}
        </p>
      )}
      {formData.observaciones && (
        <p>
          <strong>Observaciones:</strong> {formData.observaciones}
        </p>
      )}
      <h3 className="section-title-ver">Detalles de la Tabla</h3>
      {formData.tableData.map((row, index) => (
        <div key={row.id}>
          <p>
            <strong>Fila {index + 1}:</strong>
          </p>
          <p>
            <strong>Cantidad:</strong> {row.cant}
          </p>
          <p>
            <strong>Unidad de Medida:</strong> {row.u_medida}
          </p>
          <p>
            <strong>Descripción:</strong> {row.description}
          </p>
          <p>
            <strong>Valor Unitario:</strong> {row.price_unit}
          </p>
          <p>
            <strong>Valor Total:</strong> {row.v_total}
          </p>
        </div>
      ))}
    </div>
  );
};

export default OrdenVerify;
