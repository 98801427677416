import React, { useState, useEffect } from "react";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const TableFactura = ({ formData, setFormData }) => {
  const [tableData, setTableData] = useState([]);

  // Sincroniza los datos iniciales de la tabla con formData
  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, tableData: [] }));
  }, []);

  const handleInputChange = (id, field, value) => {
    const updatedTable = tableData.map((row) =>
      row.id === id
        ? {
            ...row,
            [field]: value,
            ...(field === "cant" || field === "price_unit"
              ? {
                  v_total:
                    (field === "cant" ? value : row.cant) *
                    (field === "price_unit" ? value : row.price_unit),
                }
              : {}),
          }
        : row,
    );

    setTableData(updatedTable);
    setFormData((prevData) => ({ ...prevData, tableData: updatedTable }));
  };

  const addNewRow = () => {
    const newRow = {
      id: tableData.length + 1,
      cant: 0,
      u_medida: "",
      description: "",
      price_unit: 0,
      v_total: 0,
    };

    const updatedTable = [...tableData, newRow];
    setTableData(updatedTable);
    setFormData((prevData) => ({ ...prevData, tableData: updatedTable }));
  };

  const removeRow = (id) => {
    const updatedTable = tableData.filter((row) => row.id !== id);
    setTableData(updatedTable);
    setFormData((prevData) => ({ ...prevData, tableData: updatedTable }));
  };

  const calculateSubtotal = () => {
    return tableData.reduce(
      (total, row) => total + (parseFloat(row.v_total) || 0),
      0,
    );
  };

  useEffect(() => {
    const subtotal = calculateSubtotal();
    const ivaValue = (subtotal * formData.iva) / 100;
    const total = subtotal + ivaValue;
    setFormData((prev) => ({ ...prev, subtotal, total }));
  }, [tableData, formData.iva]);

  const columns = [
    { dataKey: "cant", label: "CANTIDAD", width: "auto" },
    { dataKey: "u_medida", label: "UNIDAD DE MEDIDA", width: "auto" },
    { dataKey: "description", label: "DESCRIPCIÓN", width: "auto" },
    {
      dataKey: "price_unit",
      label: "VALOR UNITARIO",
      numeric: true,
      width: "auto",
    },
    { dataKey: "v_total", label: "VALOR TOTAL", numeric: true, width: "auto" },
    { dataKey: "actions", label: "", width: 60 },
  ];

  const customRenderers = {
    cant: (row) => (
      <TextField
        type="number"
        value={row.cant}
        label="Cantidad"
        variant="standard"
        size="small"
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
          const numericValue = Math.max(0, parseInt(value, 10) || 0); // Asegura enteros positivos
          handleInputChange(row.id, "cant", numericValue);
        }}
        inputProps={{
          step: 1, // Incremento de 1
          min: 0, // No permite valores negativos
        }}
      />
    ),
    u_medida: (row) => (
      <TextField
        type="text"
        value={row.u_medida}
        label="Kg/m/m2/L"
        variant="standard"
        size="small"
        onChange={(e) => handleInputChange(row.id, "u_medida", e.target.value)}
      />
    ),
    description: (row) => (
      <TextField
        type="text"
        value={row.description}
        label="Descripción"
        variant="standard"
        size="small"
        onChange={(e) =>
          handleInputChange(row.id, "description", e.target.value)
        }
      />
    ),
    price_unit: (row) => (
      <TextField
        type="text" // Usar "text" para controlar completamente el input
        value={row.price_unit}
        label="Valor Unitario"
        variant="standard"
        size="small"
        onChange={(e) => {
          const value = e.target.value.trim(); // Elimina espacios innecesarios
          // Validar que el valor sea un número válido con decimales
          const numericValue = value.match(/^\d*\.?\d{0,9}$/)
            ? value
            : row.price_unit;
          handleInputChange(row.id, "price_unit", numericValue);
        }}
        inputProps={{
          step: "0.01", // Incrementos de 0.01 para decimales
          min: 0, // No permite valores negativos
          inputMode: "decimal", // Optimiza el teclado en dispositivos móviles
        }}
      />
    ),

    v_total: (row) => (
      <TextField
        type="number"
        value={row.v_total}
        label="Valor Total"
        variant="standard"
        size="small"
        disabled
      />
    ),
    actions: (row) => (
      <IconButton
        color="error"
        onClick={() => removeRow(row.id)}
        title="Eliminar fila"
      >
        <RemoveCircleIcon />
      </IconButton>
    ),
  };

  return (
    <div className="table-section-mq">
      <Button
        variant="contained"
        onClick={addNewRow}
        className="save-button mq2"
      >
        Agregar Item
        <AddCircleOutlineIcon />
      </Button>
      <div className="table-section">
        {tableData.length > 0 ? (
          <ReactVirtualizedTable
            columns={columns}
            data={tableData}
            customRenderers={customRenderers}
          />
        ) : (
          <p>No hay filas. Agrega una nueva fila para comenzar.</p>
        )}
      </div>
    </div>
  );
};

export default TableFactura;
