import React, { useState, useRef, useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Dates3 from "../components/OrdenCompraForms/Dates1.js";
import FormVerify from "../hooks/FormVerify";
import "../styles/pages/OrdenCompra.pages.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useGlobalContext } from "../context/GlobalContext";
import {
  CustomTextField,
  CustomSelect,
  CustomTextarea,
  CustomDateField,
} from "../components/Forms/CustomAutocomplete.js";
import OrdenVerify from "../components/OrdenCompraForms/OrdenVerify.js";
import TableFactura from "../components/OrdenCompraForms/TableFactura.js";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom

const FormOrdenCompra = () => {
  const [showFormVerify, setShowFormVerify] = useState(false);
  const { user, area, company } = useGlobalContext();
  const [formData, setFormData] = useState({
    numeroOrden: "1234",
    centroCostos: "",
    nit: "",
    senores: "",
    sucursales: "",
    telefono: "",
    metodoPago: "",
    subtotal: 0,
    iva: 19,
    total: "",
    fechaAnteriorCompra: null,
    fechaEntrega: null,
    justificacion: "",
    observaciones: "",
    fechaAnteriordeCompra: "", // Permitir que comience como null
    tableData: [
      {
        id: 1,
        cant: "",
        u_medida: "",
        description: "",
        price_unit: "",
        v_total: "",
      },
    ],
    usuario: user,
    cargo: area,
  });

  const formDateBasicRef = useRef(null);

  const tipoDocumentoOptions = [
    { value: "CC", label: "Cédula de Ciudadanía" },
    { value: "TI", label: "Tarjeta de Identidad" },
    { value: "CE", label: "Cédula de Extranjería" },
  ];

  const handleFormDataChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const Dates3Component = useMemo(
    () => <Dates3 handleFormDataChange={handleFormDataChange} />,
    [],
  );

  // Función para validar el formulario
  const isFormValid = () => {
    const requiredFields = [
      "centroCostos",
      "nit",
      "senores",
      "sucursales",
      "telefono",
      "metodoPago",
      "fechaEntrega",
      "fechaAnteriordeCompra",
      "justificacion",
    ];

    for (const field of requiredFields) {
      // Verifica si el valor existe y es una cadena antes de llamar trim()
      if (
        !formData[field] ||
        (typeof formData[field] === "string" && formData[field].trim() === "")
      ) {
        return false;
      }
    }

    // Validar si al menos una fila de la tabla está completa
    const isTableValid = formData.tableData.some(
      (row) =>
        row.cant &&
        row.u_medida &&
        row.description &&
        row.price_unit &&
        row.v_total,
    );

    return isTableValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      alert(
        "Por favor, completa todos los campos requeridos antes de generar la orden.",
      );
      return;
    }

    setShowFormVerify(true);
  };

  return (
    <div className="creation-form-container">
      {showFormVerify && (
        <FormVerify
          formDataContent={<OrdenVerify formData={formData} />}
          onClose={() => setShowFormVerify(false)}
        />
      )}

      <div className="form-header-ordencompra">
        <p>
          {" "}
          <strong>{company}</strong>
        </p>
        <h1 className="form-title-ordencompra">
          Orden de Compra y/o Servicios
        </h1>
        <Link to="/HomeWelcomeBoard">
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <form className="form-content" onSubmit={handleSubmit}>
        <div ref={formDateBasicRef} className="form-date-basic---orden">
          {Dates3Component}
          <TableFactura formData={formData} setFormData={setFormData} />
          <div className="factura-section">
            <div className="two-column-grid">
              <CustomSelect
                id="metodo-pago"
                label="Método de Pago"
                value={
                  tipoDocumentoOptions.find(
                    (option) => option.label === formData.metodoPago,
                  )?.value || ""
                }
                options={tipoDocumentoOptions}
                onChange={(e) => {
                  const selectedOption = tipoDocumentoOptions.find(
                    (option) => option.value === e.target.value,
                  );
                  handleFormDataChange(
                    "metodoPago",
                    selectedOption?.label || "",
                  );
                }}
                required
              />

              <CustomTextField
                id="subtotal"
                label="SUBTOTAL"
                value={formData.subtotal}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-sin-limite"
                label="Fecha Anterior de Compra"
                value={formData.fechaAnteriordeCompra}
                onChange={(newValue) =>
                  handleFormDataChange("fechaAnteriordeCompra", newValue)
                }
                required
              />

              <CustomTextField
                id="iva"
                label="IVA (%)"
                value={formData.iva}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <CustomDateField
                id="fecha-entrega"
                label="Fecha de Entrega"
                value={formData.fechaEntrega}
                onChange={(newValue) =>
                  handleFormDataChange("fechaEntrega", newValue)
                }
                minDate={new Date()} // Limitar a fechas posteriores al día actual
                required
              />

              <CustomTextField
                id="total"
                label="TOTAL"
                value={formData.total}
                disabled
              />
            </div>
            <div className="two-column-grid">
              <p>
                <strong>Justificación de la Compra y/o Servicio</strong>
              </p>
              <p>
                <strong>Observaciones</strong>
              </p>
            </div>
            <div className="two-column-grid">
              <CustomTextarea
                id="justificacion"
                value={formData.justificacion}
                onChange={(value) =>
                  handleFormDataChange("justificacion", value)
                }
                placeholder="Escribe aquí la justificación..."
                minRows={5}
              />
              <CustomTextarea
                id="observaciones"
                value={formData.observaciones}
                onChange={(value) =>
                  handleFormDataChange("observaciones", value)
                }
                placeholder="Escribe aquí las observaciones..."
                minRows={5}
              />
            </div>
            <div className="two-column-grid">
              <p>
                <strong>Solicitado por: </strong> {user} <br />
                <strong>Cargo: </strong>
                {area}
              </p>
            </div>
          </div>
        </div>
        <div className="generate-button-container-orden">
          <Button variant="contained" type="submit" className="save-button">
            <SaveAltIcon />
            Generar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormOrdenCompra;
