import React, { createContext, useState, useContext } from "react";

// Crear el contexto
const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [company, setCompany] = useState(
    () => localStorage.getItem("selectedCompany") || null, // Recuperar la empresa de localStorage
  );
  const [companyId, setCompanyId] = useState(
    () => localStorage.getItem("selectedCompanyId") || null, // Recuperar el ID de la empresa de localStorage
  );
  const [isCompanySelected, setIsCompanySelected] = useState(
    () => !!localStorage.getItem("selectedCompany"), // Determinar si hay empresa seleccionada al cargar
  );

  const user = "Team Pelusa";
  const area = "Desarrollador FullStack";
  const centro_costos = "Financiero";

  // Actualizar la empresa y el estado de selección
  const updateCompany = (newCompany, newCompanyId) => {
    setCompany(newCompany);
    setCompanyId(newCompanyId); // Guarda el ID internamente
    setIsCompanySelected(!!newCompany); // Cambiar el estado según si hay empresa seleccionada

    if (newCompany) {
      localStorage.setItem("selectedCompany", newCompany); // Guardar en localStorage
      localStorage.setItem("selectedCompanyId", newCompanyId); // Guardar el ID en localStorage
    } else {
      localStorage.removeItem("selectedCompany"); // Limpiar de localStorage si no hay empresa
      localStorage.removeItem("selectedCompanyId"); // Limpiar el ID del localStorage
    }
  };

  // Método para obtener el ID de la empresa cuando sea necesario
  const getCompanyId = () => companyId;

  return (
    <GlobalContext.Provider
      value={{
        user,
        company,
        setCompany: updateCompany,
        isCompanySelected,
        setIsCompanySelected,
        area,
        centro_costos,
        getCompanyId, // Método para acceder al ID
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// Hook para usar el contexto
export const useGlobalContext = () => useContext(GlobalContext);
