import React, { useState, useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import AlertComponent from "./Alerts.js";

export function useAlert() {
  const [alertState, setAlertState] = useState({
    type: null,
    products: [], // Estado para productos seleccionados
    emisor: null, // Estado para el emisor
    estadoFactura: null,
    orden: null,
    caller: null,
  });
  const timeoutRef = useRef(null); // Referencia para el temporizador de cerrado

  // Modifica triggerAlert para aceptar el parámetro emisor
  const triggerAlert = useCallback(
    (
      type,
      {
        products = [],
        orden = null,
        emisor = null,
        estadoFactura = null,
        setRows = null,
        caller = null,
      },
    ) => {
      clearTimeout(timeoutRef.current); // Limpia cualquier temporizador previo

      // Configura el estado de la alerta con el tipo, los productos seleccionados y el emisor
      setAlertState({
        type,
        products,
        emisor,
        orden,
        estadoFactura,
        setRows,
        caller,
      });

      // Si es la alerta de "groupItems", no establecer temporizador de cierre automático
      if (type === "groupItems") return;

      // Configura el temporizador para cerrar automáticamente las alertas normales
      timeoutRef.current = setTimeout(() => {
        setAlertState({
          type: null,
          products: [],
          emisor: null,
          estadoFactura: null,
          setRows: null,
          orden: null,
          caller: null,
        });
      }, 2000); // 2 segundos de duración para las alertas normales
    },
    [],
  );

  const closeAlert = useCallback(() => {
    clearTimeout(timeoutRef.current); // Limpia cualquier temporizador activo
    setAlertState({
      type: null,
      products: [],
      emisor: null,
      estadoFactura: null,
      orden: null,
      caller: null,
    }); // Cierra la alerta manualmente
  }, []);

  const Alert = useCallback(() => {
    if (!alertState.type) return null;

    // Renderiza el AlertComponent en el portal modal-root
    return ReactDOM.createPortal(
      <AlertComponent
        type={alertState.type}
        selectedProducts={alertState.products} // Pasa los productos seleccionados
        emisor={alertState.emisor} // Pasa el emisor
        orden={alertState.orden}
        caller={alertState.caller}
        estadoFactura={alertState.estadoFactura} // Pasa el emisor
        setRows={alertState.setRows} // Pasa setRows al componente de alerta
        onClose={closeAlert} // Pasa la función `closeAlert` para manejar el cierre
      />,
      document.getElementById("modal-root"),
    );
  }, [alertState, closeAlert]);

  return { triggerAlert, Alert };
}
