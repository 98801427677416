import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "./useAuth";
import { CheckCircle, OctagonX, CircleAlert, Check, X } from "lucide-react";
import "../styles/components/alertStyle.css";
import DistribucionCostos from "../components/FacturasForms/FacturasInternas/distribucionCostos";
import TramiteFactura from "../components/FacturasForms/FacturasInternas/tramiteFacturas";
/* import { useAlert } from "../hooks/useAlert"; // Hook personalizado para alertas
 */
export default function AlertComponent({
  type,
  onClose,
  selectedProducts,
  estadoFactura,
  setRows,
  orden,
  emisor,
}) {
  const [groupedArray, setGroupedArray] = useState([]); // Estado para almacenar los grupos
  const [visible, setVisible] = useState(false);
  const [alertType, setAlertType] = useState(type); // Manejar el tipo de alerta dinámicamente
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]); // Almacena los productos deshabilitados
  const [groupedProducts, setGroupedProducts] = useState({}); // Almacena el grupo de cada producto
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0); // Índice del grupo actual
  const [allDisabled, setAllDisabled] = useState(false); // Verifica si todos los productos están deshabilitados
  const gruposFacturaItems = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""); // Array de letras del abecedario
  const [groupingByGroupClicked, setGroupingByGroupClicked] = useState(false);
  const [groupingByAllClicked, setGroupingByAllClicked] = useState(false); // Controla el estado de Agrupar Todo
  const [formData, setFormData] = useState({
    distribucionxItems: "",
    distribucionxGrupos: "",
    centroCostos: "",
    distribucionPorcentaje: "",
  });
  const [productOptions, setProductOptions] = useState([]);

  const [caller, setCaller] = useState(null);
  const { forcelogout } = useAuth();
  const timeoutId = useRef(null);

  const closessionActive2 = async () => {
    const response = await forcelogout(); // Llama a la función forcelogout
    if (response.ok && onClose) onClose(); // Cierra la alerta después de cerrar la sesión
  };

  useEffect(() => {
    if (!alertType) return;

    setVisible(true);

    // Configurar temporizadores si no es estática
    if (
      alertType !== "sessionActive" &&
      alertType !== "groupItems" &&
      alertType !== "tramiteFactura" &&
      alertType !== "distribucionCostos" // Incluye la nueva alerta
    ) {
      timeoutId.current = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          if (onClose) onClose();
        }, 1000);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [alertType, onClose]);

  useEffect(() => {
    if (alertType === "tramiteFactura") {
      // Lógica existente para tramiteFactura
      if (Array.isArray(selectedProducts) && selectedProducts.length > 0) {
        const allProductsDisabled = selectedProducts.every((product) =>
          disabledCheckboxes.includes(product),
        );

        if (emisor) {
          console.log(
            `Validando productos habilitados para el emisor: ${emisor}`,
          );
        }

        setAllDisabled(allProductsDisabled);
      } else {
        setAllDisabled(false);
      }
    } else if (alertType === "distribucionCostos" && caller === "noAgrp") {
      // Lógica para distribucionCostos, solo si el caller es "noAgrp"
      if (Array.isArray(selectedProducts) && selectedProducts.length > 0) {
        console.log(
          "Productos recibidos para DistribucionCostos (sin restricciones):",
          selectedProducts,
        );
        setProductOptions(
          selectedProducts.map((product) => ({
            label: product, // Texto visible en el desplegable
            value: product, // Valor asociado
          })),
        );
      } else {
        console.warn("No hay productos válidos para distribucionCostos.");
        setProductOptions([]); // Limpia las opciones si no hay productos
      }
    }
  }, [
    alertType,
    caller,
    disabledCheckboxes,
    selectedProducts,
    emisor,
    estadoFactura,
  ]);

  const getIcon = () => {
    if (
      alertType === "success" ||
      alertType === "saved" ||
      alertType === "invoiceApproved" ||
      alertType === "generated"
    ) {
      return <CheckCircle className="check-icon" />;
    } else if (
      alertType === "sessionActive" ||
      alertType === "groupItems" ||
      alertType === "tramiteFactura" ||
      alertType === "distribucionCostos" // Nuevo caso
    ) {
      return <CircleAlert className="session-icon" />;
    } else if (alertType === "invoiceRejected") {
      // Nueva alerta para Factura Rechazada
      return <OctagonX className="error-icon" />;
    } else {
      return <OctagonX className="error-icon" />;
    }
  };

  const getTextAndClass = () => {
    let text = "";
    let className = "";

    switch (alertType) {
      case "success":
        text = "Orden Aprobada!";
        className = "approval-text";
        break;
      case "invoiceApproved": // Nueva alerta para Factura Aprobada
        text = "Factura Aprobada!";
        className = "approval-text";
        break;
      case "saved":
        text = "Guardado Exitoso!";
        className = "approval-text";
        break;
      case "generated":
        text = "Orden Generada!";
        className = "approval-text";
        break;
      case "sessionActive":
        text = "! Tienes otra Sesión Activa ¡";
        className = "session-text";
        break;
      case "groupItems":
        text = "¿Desea agrupar los Items de la Factura?";
        className = "session-text";
        break;
      case "tramiteFactura":
        text = "Tramite Factura";
        className = "session-text";
        break;
      case "distribucionCostos": // Nuevo caso
        text = "Distribución de Costos";
        className = "session-text";
        break;
      case "invoiceRejected": // Nueva alerta para Factura Rechazada
        text = "Factura Rechazada!";
        className = "error-text";
        break;
      default:
        text = "Orden Rechazada!";
        className = "error-text";
        break;
    }

    return { text, className };
  };

  const { text, className } = getTextAndClass();

  const handAgrupar = (selectedProducts) => {
    if (!selectedProducts || selectedProducts.length === 0) {
      console.log("No hay productos seleccionados para agrupar.");
      return;
    }
    console.log("Productos recibidos en Agrupar:", selectedProducts);

    setTimeout(() => {
      setAlertType("tramiteFactura"); // Cambia a la alerta "tramiteFactura"
      setVisible(true);
    }, 500); // Retardo para transición suave
  };
  // Función que dispara la alerta
  const handleNoAgrupar = (selectedProducts) => {
    if (!selectedProducts || selectedProducts.length === 0) {
      console.log("No hay productos seleccionados para No-Agrupar.");
      return;
    }

    console.log("Productos recibidos en No-Agrupar:", selectedProducts);

    // Actualiza el estado del caller
    setCaller("noAgrp");

    // Cambia el tipo de alerta
    setAlertType("distribucionCostos");
  };

  const handAgruparTodo = () => {
    // Selecciona todos los productos que no estén agrupados aún
    const selected = selectedProducts.filter(
      (product) => !groupedProducts[product], // Solo los no agrupados
    );

    if (selected.length === 0) {
      console.log("No hay productos disponibles para agrupar.");
      return;
    }

    // Usa el primer grupo disponible
    const currentGroup = gruposFacturaItems[currentGroupIndex];

    console.log("Productos agrupados en Agrupar Todo:", selected);
    console.log(`Asignando todos al grupo: Grupo ${currentGroup}`);

    // Marca los checkboxes seleccionados como checkeados y deshabilitados
    selected.forEach((product) => {
      const checkbox = document.getElementById(product);
      if (checkbox) {
        checkbox.checked = true; // Muestra el checkbox como seleccionado
        checkbox.disabled = true; // Deshabilita el checkbox
      }
    });

    // Actualiza los estados de agrupación
    setDisabledCheckboxes((prev) => [...prev, ...selected]);
    setGroupedProducts((prev) => {
      const updatedGroups = { ...prev };
      selected.forEach((product) => {
        updatedGroups[product] = `Grupo ${currentGroup}`; // Asigna el grupo actual
      });

      // Organizar los productos agrupados como un objeto con arrays por grupo
      const groupedArray = gruposFacturaItems.reduce((acc, group) => {
        const productsInGroup = Object.entries(updatedGroups)
          .filter(([, value]) => value === `Grupo ${group}`) // Filtra los productos de ese grupo
          .map(([key]) => key); // Obtiene los nombres de los productos

        if (productsInGroup.length > 0) {
          acc[`Grupo ${group}`] = productsInGroup; // Asigna el grupo como clave y productos como array
        }
        return acc;
      }, {});

      console.log(
        "Productos agrupados organizados como arrays por grupo (Agrupar Todo):",
        groupedArray,
      );

      return updatedGroups;
    });

    // Incrementa el índice del grupo para la próxima agrupación
    setCurrentGroupIndex((prevIndex) =>
      prevIndex + 1 < gruposFacturaItems.length ? prevIndex + 1 : prevIndex,
    );
    // Indica que se hizo clic en "Agrupar Todo"
    setGroupingByAllClicked(true);

    console.log("Índice del grupo actualizado a:", currentGroupIndex + 1);
  };

  const handAgruparxItems = () => {
    // Encuentra todos los checkboxes seleccionados
    const selected = selectedProducts.filter(
      (product) =>
        document.getElementById(product)?.checked && !groupedProducts[product],
    );

    if (selected.length === 0) {
      console.log("No hay nuevos productos seleccionados para agrupar.");
      return;
    }

    setGroupingByGroupClicked(true); // Indica que se agrupó por grupo

    // Obtiene la letra actual del grupo
    const currentGroup = gruposFacturaItems[currentGroupIndex];

    console.log("Productos seleccionados para agrupar:", selected);
    console.log(`Asignando al grupo: Grupo ${currentGroup}`);

    // Deshabilita los checkboxes seleccionados y asigna grupo
    setDisabledCheckboxes((prev) => [...prev, ...selected]);
    setGroupedProducts((prev) => {
      const updatedGroups = { ...prev };
      selected.forEach((product) => {
        updatedGroups[product] = `Grupo ${currentGroup}`; // Asigna el grupo actual
      });

      // Organizar los productos agrupados como un objeto con arrays por grupo
      const groupedArray = gruposFacturaItems.reduce((acc, group) => {
        const productsInGroup = Object.entries(updatedGroups)
          .filter(([, value]) => value === `Grupo ${group}`) // Filtra los productos de ese grupo
          .map(([key]) => key); // Obtiene los nombres de los productos

        if (productsInGroup.length > 0) {
          acc[`Grupo ${group}`] = productsInGroup; // Asigna el grupo como clave y productos como array
        }
        return acc;
      }, {});

      console.log(
        "Productos agrupados organizados como arrays por grupo:",
        groupedArray,
      );

      return updatedGroups;
    });

    // Incrementa el índice del grupo para la próxima agrupación
    setCurrentGroupIndex((prevIndex) =>
      prevIndex + 1 < gruposFacturaItems.length ? prevIndex + 1 : prevIndex,
    );

    console.log("Índice del grupo actualizado a:", currentGroupIndex + 1);
  };
  const onFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    console.log(`${field} actualizado con:`, value);
  };

  const tipoDocumentoOptions = [
    { value: "CC", label: "IT" },
    { value: "TI", label: "Recursos Humanos" },
    { value: "CE", label: "Contabilidad" },
    { value: "BG", label: "Bogota" },
    { value: "BC", label: "Barranquilla" },
    { value: "BN", label: "Buenaventura" },
  ];

  return (
    <div className={`container ${visible ? "visible" : ""}`}>
      {alertType === "distribucionCostos" ? (
        <DistribucionCostos
          className={className}
          productOptions={productOptions}
          setRows={setRows} // Pasa setRows al componente
          orden={orden}
          formData={formData}
          setCaller={setCaller} // Pasar setCaller
          text={text}
          caller={caller} // Aquí pasamos el valor de caller
          emisor={emisor}
          estadoFactura={estadoFactura}
          onFieldChange={onFieldChange}
          tipoDocumentoOptions={tipoDocumentoOptions}
          onClose={onClose}
          setAlertType={setAlertType} // Pasar la función para cambiar el estado
          groupedArray={groupedArray} // Pasar los grupos formados
        />
      ) : alertType === "tramiteFactura" ? (
        <TramiteFactura
          className={className}
          text={text}
          onClose={onClose}
          selectedProducts={selectedProducts}
          setCaller={setCaller} // Pasar setCaller
          emisor={emisor}
          estadoFactura={estadoFactura}
          disabledCheckboxes={disabledCheckboxes}
          groupedProducts={groupedProducts}
          allDisabled={allDisabled}
          groupingByAllClicked={groupingByAllClicked}
          groupingByGroupClicked={groupingByGroupClicked}
          handAgruparxItems={handAgruparxItems}
          handAgruparTodo={handAgruparTodo}
          setAlertType={setAlertType}
          setGroupedArray={setGroupedArray} // Función para actualizar los grupos
        />
      ) : (
        <div
          className={`approval-box ${
            alertType === "sessionActive" || alertType === "groupItems"
              ? "session-active"
              : ""
          }`}
        >
          {getIcon()}
          <p className={className}>{text}</p>
          {alertType === "sessionActive" && (
            <div className="button-container">
              <button onClick={onClose} className="alert-button red">
                <X className="icon" />
                Volver al Login
              </button>
              <button
                onClick={closessionActive2}
                className="alert-button green"
              >
                <Check className="icon" />
                Cerrar Sesión Activa
              </button>
            </div>
          )}
          {alertType === "groupItems" && (
            <div className="button-container">
              <button
                onClick={() => handleNoAgrupar(true)}
                className="alert-button red"
              >
                <X className="icon" />
                No Agrupar
              </button>
              <button
                onClick={() => handAgrupar(true)}
                className="alert-button green"
              >
                <Check className="icon" />
                Agrupar Items
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
