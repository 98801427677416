import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactVirtualizedTable from "../ReactVirtualizedTable";
import PaginationComponent from "../PaginationComponent";

const TableFactura = ({ filters }) => {
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const getRandomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  const getRandomDate = () => {
    const today = new Date();
    const randomDays = getRandomNumber(0, 30);
    const randomDate = new Date(today);
    randomDate.setDate(today.getDate() + randomDays);
    return randomDate;
  };

  const estados = ["Pendiente", "Aprobado", "Rechazado"];
  const centrosCostos = ["Financiero", "Operativo", "Administrativo", "Ventas"];

  const generateRows = () =>
    Array.from({ length: 50 }, (_, index) => ({
      orden: index + 1,
      fechaGenerada: getRandomDate().toLocaleDateString("es-CO"),
      fechaEntrega: getRandomDate().toLocaleDateString("es-CO"),
      centroCostos: centrosCostos[getRandomNumber(0, centrosCostos.length - 1)],
      estado: estados[getRandomNumber(0, estados.length - 1)],
      documento: "Ver Orden",
    }));

  useEffect(() => {
    const generatedRows = generateRows();
    setRows(generatedRows);
  }, []);
  const filteredRows = rows.filter((row) => {
    const from = filters.fechaordenDesde
      ? new Date(filters.fechaordenDesde)
      : null;
    const to = filters.fechaordenHasta
      ? new Date(filters.fechaordenHasta)
      : null;
    const fechaGenerada = new Date(row.fechaGenerada);

    const isWithinDateRange =
      (!from || fechaGenerada >= from) && (!to || fechaGenerada <= to);
    const matchesEstado =
      !filters.estadOrden || row.estado === filters.estadOrden;

    return isWithinDateRange && matchesEstado;
  });

  const sortedRows = filteredRows.sort((a, b) => {
    if (a.estado === "Pendiente" && b.estado !== "Pendiente") return -1;
    if (a.estado !== "Pendiente" && b.estado === "Pendiente") return 1;
    return 0;
  });

  const getPaginatedRows = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedRows.slice(startIndex, endIndex);
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    { dataKey: "orden", label: "ORDEN", width: "auto" },
    { dataKey: "fechaGenerada", label: "FECHA GENERADA", width: "auto" },
    { dataKey: "fechaEntrega", label: "FECHA DE ENTREGA", width: "auto" },
    { dataKey: "centroCostos", label: "CENTRO DE COSTOS", width: "auto" },
    { dataKey: "estado", label: "ESTADO", width: "auto" },
    {
      dataKey: "documento",
      label: "DOCUMENTO",
      width: "auto",
      cellRenderer: ({ rowData }) => (
        <Link to={`/detalle-orden/${rowData.orden}`} className="link">
          {rowData.documento}
        </Link>
      ),
    },
  ];

  return (
    <div className="table-section up">
      <ReactVirtualizedTable
        columns={columns}
        data={getPaginatedRows()}
        customRenderers={{
          documento: (rowData) => (
            <Link to={`/detalle-orden/${rowData.orden}`} className="link">
              {rowData.documento}
            </Link>
          ),
        }}
      />
      <PaginationComponent
        totalItems={sortedRows.length}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

export default TableFactura;
