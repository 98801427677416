import React, { useState, useEffect } from "react";
import { CustomSelect, CustomDateField } from "../Forms/CustomAutocomplete";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const Dates1 = ({ handleFormDataChange, onBuscar }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    fechaordenDesde: null,
    fechaordenHasta: null,
    estadOrden: "Pendiente",
  });

  const tipoDocumentoOptions = [
    { value: "Pendiente", label: "Pendiente" },
    { value: "Aprobado", label: "Aprobado" },
    { value: "Rechazado", label: "Rechazado" },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      const newDate = new Date();
      setCurrentDateTime(newDate);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value);
  };

  const handleBuscar = () => {
    if (onBuscar) onBuscar(formData);
  };

  const formatDate = (date) => {
    return date
      ? date.toLocaleString("es-CO", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p>
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
      </div>
      <div className="three-column-grid">
        <CustomDateField
          id="fecha-desde"
          label="Fecha Orden (Desde)"
          value={formData.fechaordenDesde}
          onChange={(newValue) =>
            handleFieldChange("fechaordenDesde", newValue)
          }
        />
        <CustomDateField
          id="fecha-hasta"
          label="Fecha Orden (Hasta)"
          value={formData.fechaordenHasta}
          onChange={(newValue) =>
            handleFieldChange("fechaordenHasta", newValue)
          }
        />
        <CustomSelect
          id="estado-orden"
          label="Estado"
          value={formData.estadOrden}
          options={tipoDocumentoOptions}
          onChange={(e) => handleFieldChange("estadOrden", e.target.value)}
        />
      </div>
      <div className="generate-button-container-orden">
        <Button
          variant="contained"
          className="save-button"
          onClick={handleBuscar}
        >
          <SaveAltIcon />
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default Dates1;
