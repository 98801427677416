import React, { useState, useEffect } from "react";
import {
  CustomAutocomplete,
  CustomTextField,
  CustomSelect,
} from "../Forms/CustomAutocomplete";

const Dates3 = ({ handleFormDataChange }) => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [formData, setFormData] = useState({
    centroCostos: "", // Centro de Costos
    nit: "", // NIT
    senores: "", // Señor(es)
    sucursales: "", // Sucursales
    telefono: "", // Teléfono
    fechaHoraActual: new Date(), // Campo para capturar la fecha y hora actual
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const newDate = new Date();
      setCurrentDateTime(newDate);
      onFieldChange("fechaHoraActual", newDate); // Actualiza `formData` con la nueva fecha y hora
    }, 1000);

    return () => clearInterval(timer); // Limpia el intervalo al desmontar el componente
  }, []);

  const tipoDocumentoOptions = [
    { value: "CC", label: "Cédula de Ciudadanía" },
    { value: "TI", label: "Tarjeta de Identidad" },
    { value: "CE", label: "Cédula de Extranjería" },
  ];

  const onFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    handleFormDataChange(field, value); // Propaga cambios al padre si es necesario
  };

  const formatDate = (date) => {
    return date.toLocaleString("es-CO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div className="orden-date1-section">
      <div className="two-column-grid">
        <p>
          <strong>Fecha: </strong> {formatDate(currentDateTime)}
        </p>
      </div>
      <div className="three-column-grid">
        <CustomSelect
          id="centro-costos"
          className="item-1"
          label="Centro de Costos"
          value={
            tipoDocumentoOptions.find(
              (option) => option.label === formData.centroCostos,
            )?.value || ""
          } // Encuentra el valor correcto basado en el label almacenado en formData
          options={tipoDocumentoOptions}
          onChange={(e) => {
            const selectedOption = tipoDocumentoOptions.find(
              (option) => option.value === e.target.value,
            );
            onFieldChange("centroCostos", selectedOption?.label || ""); // Guarda el label en formData
          }}
          required
        />

        <CustomTextField
          id="nit"
          label="Número de Identificación del Proveedor"
          className="item-2"
          value={formData.nit}
          onChange={(e) => {
            const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
            const truncatedValue = inputValue.slice(0, 15); // Limita a un máximo de 15 caracteres
            onFieldChange("nit", truncatedValue); // Actualiza el valor en formData
          }}
        />

        <CustomAutocomplete
          className="item-3"
          options={tipoDocumentoOptions.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          value={tipoDocumentoOptions.find(
            (option) => option.label === formData.senores,
          )}
          onChange={(event, value) =>
            onFieldChange("senores", value?.label || "")
          }
          label="Señor(es)"
          required
        />
      </div>

      <div className="two-column-grid">
        <CustomAutocomplete
          options={tipoDocumentoOptions.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          value={tipoDocumentoOptions.find(
            (option) => option.label === formData.sucursales,
          )}
          onChange={(event, value) =>
            onFieldChange("sucursales", value?.label || "")
          }
          label="Sucursales"
          required
        />

        <CustomAutocomplete
          options={tipoDocumentoOptions.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          value={tipoDocumentoOptions.find(
            (option) => option.label === formData.telefono,
          )}
          onChange={(event, value) =>
            onFieldChange("telefono", value?.label || "")
          }
          label="Teléfono"
          required
        />
      </div>
    </div>
  );
};

export default Dates3;
