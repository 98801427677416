// PaginationComponent.js
import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PaginationComponent = ({
  totalItems,
  rowsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  return (
    <Stack spacing={2} className="pagination-container">
      <Pagination
        count={totalPages} // Número total de páginas
        page={currentPage} // Página actual
        onChange={(_, page) => onPageChange(page)} // Llama a la función de cambio de página
        color="primary"
      />
    </Stack>
  );
};

export default PaginationComponent;
