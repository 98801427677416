import React from "react";
import ReactDOM from "react-dom";
import loadingGif from "../../src/styles/gifs/barco-de-carga.gif"; // Ajusta la ruta según donde esté tu GIF
import "../../src/styles/components/loading.css"; // Asegúrate de tener un archivo CSS para estilos

const Loading = () => {
  const content = (
    <div className="loading-overlay">
      <div className="loading-container">
        <img src={loadingGif} alt="Loading..." className="loader" />
      </div>
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById("modal-root"));
};

export default Loading;
