const API_URL = process.env.REACT_APP_API_URL;

export const fetchEmpresas = async () => {
  try {
    const response = await fetch(`${API_URL}empresa/`);
    if (!response.ok) {
      throw new Error("Error al obtener las empresas");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    return [];
  }
};
