import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import AlertComponent from "../hooks/Alerts.js";
import { useAuth } from "../hooks/useAuth.js"; // Importar el hook de AuthContext

const Dashboard = () => {
  const [alertType, setAlertType] = useState(null);
  const { refreshAuthToken } = useAuth(); // Desestructurar la función de refresco de token

  const handleAlert = (type) => {
    setAlertType(type); // Solo establece el tipo de alerta
  };

  const handleCloseAlert = () => {
    setAlertType(null); // Ocultar alerta
  };

  // Función para probar el refresh token
  const handleRefreshToken = async () => {
    const success = await refreshAuthToken();
    if (success) {
      console.log("Token refreshed successfully");
    } else {
      console.log("Failed to refresh token");
    }
  };

  return (
    <div className="creation-form-container">
      <Typography variant="h4">Dashboard</Typography>
      <div style={{ margin: "10px 185px" }}>
        <Button variant="contained" onClick={() => handleAlert("success")}>
          Mostrar Éxito
        </Button>
        <Button variant="contained" onClick={() => handleAlert("saved")}>
          Mostrar Guardado
        </Button>
        <Button variant="contained" onClick={() => handleAlert("generated")}>
          Mostrar Generada
        </Button>
        <Button
          variant="contained"
          onClick={() => handleAlert("sessionActive")}
        >
          Mostrar Sesión Activa
        </Button>
        <Button variant="contained" onClick={() => handleAlert("error")}>
          Mostrar Error
        </Button>
        <Button variant="contained" onClick={handleRefreshToken}>
          Refrescar Token
        </Button>
      </div>
      {alertType && (
        <AlertComponent type={alertType} onClose={handleCloseAlert} />
      )}
    </div>
  );
};

export default Dashboard;
