import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { verificarAsociadoPorNit } from "../services/apiService";

const DocumentoInputGroup = ({
  tipoDocumento,
  setTipoDocumento,
  tiposIdentificacion,
  nit,
  dv,
  handleNitChange,
  handleChange,
  onFieldChange,
}) => {
  const [errorMessage, setErrorMessage] = useState(""); // Mensaje de error
  const [successMessage, setSuccessMessage] = useState(""); // Mensaje de éxito

  const validateNit = async () => {
    if (nit) {
      try {
        const existe = await verificarAsociadoPorNit(nit);
        if (existe) {
          setErrorMessage("El número de identificación ya existe.");
          setSuccessMessage(""); // Limpia el mensaje de éxito
          handleNitChange({ target: { value: "" } }); // Limpia el campo
          onFieldChange("numero", ""); // Limpia el estado en el formulario
        } else {
          setErrorMessage(""); // Limpia el mensaje de error
          setSuccessMessage("Número de identificación válido."); // Muestra el mensaje de éxito
        }
      } catch (error) {
        console.error("Error al verificar el número:", error);
        setErrorMessage("Error al verificar el número.");
        setSuccessMessage(""); // Limpia el mensaje de éxito
      }
    }
  };

  return (
    <div className="three-column-grid">
      <FormControl className="input-form-control" required>
        <InputLabel id="tipo-documento-label" className="input-label">
          Tipo de Documento
        </InputLabel>
        <Select
          labelId="tipo-documento-label"
          id="tipo-documento-juridica"
          value={tipoDocumento}
          label="Tipo de Documento"
          onChange={(e) => {
            setTipoDocumento(e.target.value); // Mantener el valor seleccionado en el estado local
            handleChange(e); // Propagar el cambio
            onFieldChange("tipoDocumento", e.target.value); // Enviar el value al formData
          }}
          className="input-select"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {tiposIdentificacion.map((tipo) => (
            <MenuItem key={tipo.codigo_dian} value={tipo.codigo_dian}>
              {tipo.descripcion}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        id="numero-juridica"
        label="Número"
        variant="outlined"
        className="input-textfield"
        required
        value={nit}
        onChange={(e) => {
          handleNitChange(e); // Actualiza el campo en el padre
          onFieldChange("numero", e.target.value); // Enviar el value directamente
        }}
        onBlur={validateNit} // Valida al perder el foco
        error={!!errorMessage} // Mostrar error visual
        helperText={errorMessage || successMessage || " "} // Mensaje de error, éxito o espacio vacío
      />

      <TextField
        disabled
        id="dv-juridica"
        label="DV"
        variant="outlined"
        value={dv}
        className="input-textfield"
      />
    </div>
  );
};

export default DocumentoInputGroup;
