import React, { createContext, useContext, useMemo } from "react";
import useFetchData from "../hooks/useFetchData";
import { fetchTiposIdentificacion, fetchCiiu } from "../services/apiService";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const tiposIdentificacion = useFetchData(fetchTiposIdentificacion);
  const ciiuOptions = useFetchData(fetchCiiu);

  const value = useMemo(
    () => ({
      tiposIdentificacion: tiposIdentificacion || { data: [] },
      ciiuOptions: ciiuOptions || { data: [] },
    }),
    [tiposIdentificacion, ciiuOptions],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useDataContext = () => useContext(DataContext);
