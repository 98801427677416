import React from "react";
import { Check } from "lucide-react";
import IconButton from "@mui/material/IconButton";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { Link } from "react-router-dom"; // Importa Link de react-router-dom
const TramiteFactura = ({
  emisor,
  className,
  text,
  selectedProducts,
  disabledCheckboxes,
  groupedProducts,
  allDisabled,
  groupingByAllClicked,
  groupingByGroupClicked,
  handAgruparxItems,
  handAgruparTodo,
  onClose,
  setCaller,
  setAlertType,
  setGroupedArray, // Recibe esta prop
}) => {
  const handleCostos = () => {
    const groupedArray = Object.keys(groupedProducts).reduce((acc, product) => {
      const group = groupedProducts[product];
      if (!acc[group]) acc[group] = []; // Si el grupo no existe, inicialízalo
      acc[group].push(product); // Agrega el producto al grupo correspondiente
      return acc;
    }, {});

    console.log("Grupos con contenido:", groupedArray);

    // Actualiza el estado solo si hay cambios
    setGroupedArray((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(groupedArray)) {
        return groupedArray; // Solo actualiza si el estado es diferente
      }
      return prev; // No actualizar si no hay cambios
    });

    // Cambiar caller a "costos" y alertType a "distribucionCostos"
    setCaller("costos");
    setAlertType("distribucionCostos");
  };

  return (
    <div className="tramite-factura-container">
      {/* Contenedor específico para tramiteFactura */}
      <div className="form-header-alerts">
        <p className={className}>{text}</p>
        <Link
          onClick={(event) => {
            event.preventDefault(); // Evita la redirección
            onClose(); // Llama correctamente a la función pasada como prop
          }}
        >
          <IconButton aria-label="back" className="back-button">
            <KeyboardReturnIcon />
          </IconButton>
        </Link>
      </div>
      <div className="cont-factura">
        <div className="cont-factura-2">
          <p className={className}>EMISOR: {emisor}</p>

          {/* Mostrar los productos seleccionados */}
          {selectedProducts && selectedProducts.length > 0 ? (
            selectedProducts.map((product, index) => (
              <div key={index} className="three-column-grid">
                <label
                  className={`checkbox-label ${
                    disabledCheckboxes.includes(product) ? "disabled-label" : ""
                  }`}
                >
                  <input
                    id={product}
                    className="form-checkbox"
                    type="checkbox"
                    value={product}
                    disabled={disabledCheckboxes.includes(product)} // Deshabilitar si ya está agrupado
                  />
                  {product}
                </label>

                {/* Mostrar el grupo asignado */}
                {groupedProducts[product] && (
                  <span className="group-label">
                    {groupedProducts[product]}
                  </span>
                )}
              </div>
            ))
          ) : (
            <p>No hay productos seleccionados.</p>
          )}
        </div>
      </div>
      <div className="button-container-tramite">
        <button
          onClick={handleCostos}
          className={`alert-button green ${allDisabled ? "" : "disabled-label"}`}
          disabled={!allDisabled}
        >
          <Check className="icon" />
          Costos
        </button>

        <button
          className={`alert-button green ${
            groupingByAllClicked ? "disabled-label" : ""
          }`}
          disabled={groupingByAllClicked} // Deshabilita el botón si "Agrupar Todo" fue usado
          onClick={handAgruparxItems} // Agrupa los productos seleccionados
        >
          <Check className="icon" />
          Distribuir por Grupo
        </button>

        <button
          className={`alert-button green ${
            groupingByGroupClicked ? "disabled-label" : ""
          }`}
          disabled={groupingByAllClicked} // Deshabilita si ya se hizo clic en "Agrupar Todo"
          onClick={handAgruparTodo}
        >
          <Check className="icon" />
          Agrupar Todo
        </button>
      </div>
    </div>
  );
};

export default TramiteFactura;
