import React, { useState, useEffect } from "react";
import DocumentoInputGroup from "../DocumentInGroup";
import {
  CustomAutocomplete,
  CustomTextField,
} from "../Forms/CustomAutocomplete";
import useNitDV from "../../hooks/useNitDV";
import { fetchTiposIdentificacion, fetchCiiu } from "../../services/apiService";

const PersonaJuridica = ({ handleChange, handleFormDataChange }) => {
  const { nit, dv, handleNitChange } = useNitDV();
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [tipoCiuu, setTipoCiuu] = useState(null);
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [ciiuOptions, setCiiuOptions] = useState([]);
  const [formData, setFormData] = useState({
    razonSocial: "",
    correo: "",
  });
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setTiposIdentificacion(await fetchTiposIdentificacion());
      setCiiuOptions(await fetchCiiu());
    };
    fetchData();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const onFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    if (field === "correo") {
      setEmailError(!validateEmail(value)); // Actualiza el estado del error
    }
    handleFormDataChange(field, value); // Asegura que siempre envía el value
  };

  return (
    <div className="personal-juridica-section">
      <DocumentoInputGroup
        tipoDocumento={tipoDocumento}
        setTipoDocumento={setTipoDocumento}
        tiposIdentificacion={tiposIdentificacion}
        nit={nit}
        dv={dv}
        handleNitChange={handleNitChange}
        handleChange={handleChange}
        onFieldChange={onFieldChange}
      />

      <div className="three-column-grid">
        <CustomTextField
          id="razon-social-juridica"
          label="Razón Social"
          value={formData.razonSocial}
          onChange={(e) => onFieldChange("razonSocial", e.target.value)}
          required
        />

        <CustomTextField
          id="correo-juridica"
          label="Correo"
          value={formData.correo}
          onChange={(e) => onFieldChange("correo", e.target.value)}
          required
          error={emailError}
          helperText={emailError ? "Por favor ingresa un correo válido" : ""}
        />

        <CustomAutocomplete
          options={ciiuOptions.map((ciiu) => ({
            label: `${ciiu.clase} - ${ciiu.descripcion}`,
            value: ciiu.ciiu_id, // Asegura que el value sea el ID
          }))}
          value={tipoCiuu}
          onChange={(event, value) => {
            setTipoCiuu(value || null);
            onFieldChange("ciiu", value?.value || ""); // Envía el value al formData
          }}
          label="CIIU"
          required
        />
      </div>
      <CustomTextField
        id="ciiu-completo-natural"
        label="CIIU Completo"
        value={tipoCiuu?.label || ""} // Muestra el label pero no lo envía al formData
        disabled
      />
    </div>
  );
};

export default PersonaJuridica;
